import "./Loading.scss";
import Image from "next/image";
import Logo from "@/assets/images/logos/logo-text.svg";
import { useEffect, useState } from "react";
import classNames from "classnames";
import {useTranslations} from "next-intl";

const Loading = () => {
    const [isActive, setIsActive] = useState(false);
    const [counter, setCounter] = useState(0);

    const t = useTranslations("loading");

    /*useEffect(() => {

        const intervalDuration = 1; // Duration of each interval in milliseconds
        const counterInterval = setInterval(() => {
            setCounter((prevCounter) => {
                if (prevCounter >= 100) {
                    setIsActive(true);
                    clearInterval(counterInterval);
                    return 100;
                }
                return prevCounter + 1;
            });
        }, intervalDuration);

        return () => {
            clearInterval(counterInterval);
        };
    }, []);*/

    return (
        <div className={classNames("fixed-loading", { active: isActive })}>
            <span>{t("title")}</span>
            <div className="counter">
                <div className="numbers">
                    {Array.from({ length: 100 }).map((_, index) => (
                        <span key={index}>{`${index}%`}</span>
                    ))}
                </div>
            </div>
            <div className={"fixed-loading-shadow"}>
                <div className={"fixed-loading-shadow-left"}>

                </div>
                <div className={"fixed-loading-shadow-right"}>

                </div>
            </div>
        </div>
    );
};

export default Loading;
