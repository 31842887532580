import React, { createContext, useContext, useState, ReactNode } from "react";
import { Dialog } from "primereact/dialog";
import dynamic from "next/dynamic";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

type ModalMethods = {
  openModal: (video: {
    source: string;
    thumbnail?: string;
    title?: string;
  }) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalMethods | undefined>(undefined);

type ModalProviderProps = {
  children: ReactNode;
};

export const VideoModalProvider: React.FC<ModalProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [video, setVideo] = useState<{
    source: string;
    thumbnail?: string;
    title?: string;
  } | null>(null);

  const openModal = (video: {
    source: string;
    thumbnail?: string;
    title?: string;
  }) => {
    console.log("open")
    setIsOpen(true);
    setVideo(video);
  };

  const closeModal = () => {
    setIsOpen(false);
    setVideo(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}

      <Dialog
        visible={isOpen && !!video}
        onHide={closeModal}
        draggable={false}
        style={{
          minWidth: 500,
        }}
        header={video?.title}
      >
        <ReactPlayer
          width={"100%"}
          height={400}
          light={video?.thumbnail}
          url={video?.source}
          playing={false}
          controls={true}
        />
      </Dialog>
    </ModalContext.Provider>
  );
};

export const useVideoModal = (): ModalMethods => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
