"use client"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthProvider from "@/contexts/AuthProvider";
import { ToastProvider } from "@/contexts/ToastProvider";
import {ReactNode, useEffect, useState} from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { VideoModalProvider } from "@/contexts/VideoModalProvider";
import Loading from "@/components/atom/Loading/Loading";

interface IProps {
  children: ReactNode;
}

const queryClient = new QueryClient();

const MainLayout = ({ children }: IProps) => {

  return (
    <QueryClientProvider client={queryClient}>
        <VideoModalProvider>
            <Loading/>
          <ToastProvider>{children}</ToastProvider>
        </VideoModalProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default MainLayout;
