"use client"
import React, { createContext, useContext, useRef, ReactNode } from 'react';
import {Toast} from "primereact/toast";
import {PrimeReactProvider} from "primereact/api";

type ToastMethods = {
    success: (detail: string | ReactNode) => void;
    error: (detail: string | ReactNode) => void;
    info: (detail: string | ReactNode) => void;
    warning: (detail: string | ReactNode) => void;
};

const ToastContext = createContext<ToastMethods | undefined>(undefined);

type ToastProviderProps = {
    children: ReactNode;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const toastRef = useRef<any>(null);  // Change `any` to the type that your Toast component expects

    const show = (options: { severity: string, summary: string, detail: string }) => {
        toastRef.current && toastRef.current.show(options);
    };

    //@ts-ignore
    const success = (detail: string | ReactNode) => show({ severity: 'success', summary: 'Úspěch', detail });
    //@ts-ignore

    const error = (detail: string | ReactNode) => show({ severity: 'error', summary: 'Chyba', detail });
    //@ts-ignore

    const info = (detail: string | ReactNode) => show({ severity: 'info', summary: 'Info', detail });
    //@ts-ignore

    const warning = (detail: string | ReactNode) => show({ severity: 'warn', summary: 'Upozornění', detail });

    return (
        <ToastContext.Provider value={{ success, error, info, warning }}>
            <PrimeReactProvider>
            <Toast ref={toastRef} />
            {children}
            </PrimeReactProvider>
        </ToastContext.Provider>
    );
};

export const useToast = (): ToastMethods => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};
